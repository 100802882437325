import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import "../css/Blog.css";
import { baseUrl } from "../data/constant";

const BlogDetail = () => {
  const { id } = useParams(); // Get the blog ID from the URL
  const navigate = useNavigate();
  const [blog, setBlog] = useState(null);
  const isDarkMode = localStorage.getItem("themeMode") === "Dark";

  useEffect(() => {
    // Fetch the blog by ID
    const fetchBlog = async () => {
      try {
        const token = localStorage.getItem("token");
        const response = await fetch(`${baseUrl}/admin/get-blogs/${id}`, {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        });
        const data = await response.json();
        if (data.blog) {
          setBlog(data.blog);
        }
      } catch (error) {
        console.error("Error fetching blog:", error);
      }
    };

    fetchBlog();
  }, [id]);

  if (!blog) return <div>Loading...</div>;

  return (
    <div
      className={`blog-detail-container p-6 rounded-xl shadow-xl max-w-4xl mx-auto mt-10 ${
        isDarkMode ? "bg-gray-800 text-white" : "bg-white text-gray-800"
      }`}
    >
      <h2
        className={`text-3xl font-semibold mb-6 ${
          isDarkMode ? "text-indigo-400" : "text-indigo-600"
        }`}
      >
        {blog.title}
      </h2>
      <div
        className={`blog-content text-lg leading-relaxed mb-6 ${
          isDarkMode ? "text-gray-900" : "text-gray-800"
        }`}
        dangerouslySetInnerHTML={{ __html: blog.content }}
      />
      <p
        className={`text-sm ${isDarkMode ? "text-gray-500" : "text-gray-600"}`}
      >
        Published on {new Date(blog.createdAt).toLocaleDateString()}
      </p>
      <button
        onClick={() => navigate("/view-blogs")}
        className={`mt-6 px-6 py-3 rounded-lg font-semibold shadow-md transition-all duration-300 ${
          isDarkMode
            ? "bg-indigo-600 text-white hover:bg-indigo-700"
            : "bg-indigo-100 text-indigo-700 hover:bg-indigo-200"
        }`}
      >
        Back to Blog List
      </button>
    </div>
  );
};

export default BlogDetail;

import React, { useEffect, useState } from "react";
import axios from "axios";
import { useStateContext } from "../contexts/ContextProvider";
import { baseUrl } from "../data/constant";
import Loader from "./Loader"; // Ensure the Loader component is correctly imported

const UpdateLinks = () => {
  const { currentMode, currentColor } = useStateContext();
  const [links, setLinks] = useState({
    discord: "",
    telegram: "",
    tradingView: "",
  });
  const [isLoading, setIsLoading] = useState(false);
  const [message, setMessage] = useState("");

  useEffect(() => {
    const fetchLinks = async () => {
      setIsLoading(true);
      const token = localStorage.getItem("token");
      try {
        const { data } = await axios.get(`${baseUrl}/admin/links`, {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        });

        if (data.success && data.links) {
          setLinks({
            discord: data.links.discord || "",
            telegram: data.links.telegram || "",
            tradingView: data.links.tradingView || "",
          });
          setIsLoading(false);
        } else {
          setMessage("Failed to fetch links.");
        }
      } catch (error) {
        console.error("Error fetching links:", error);
        setMessage("An error occurred while fetching links.");
      }
    };

    fetchLinks();
  }, []);

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setLinks((prev) => ({ ...prev, [name]: value }));
  };

  const handleUpdateLinks = async () => {
    const token = localStorage.getItem("token");
    setIsLoading(true);
    setMessage("");
    try {
      const { data } = await axios.post(
        `${baseUrl}/admin/create-links`,
        links,
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
        }
      );

      if (data.success) {
        setMessage("Links updated successfully!");
      } else {
        setMessage("Failed to update links. Please try again.");
      }
    } catch (error) {
      console.error("Error updating links:", error);
      setMessage("An error occurred. Please try again.");
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <section className="dark:text-white-shade text-black-shade body-font">
      <div className="container px-5 py-20 md:py-5 mx-auto">
        <div className="flex justify-between items-center w-full mb-10">
          <h1
            style={{
              color: currentColor,
            }}
            className="text-xl md:text-4xl font-medium title-font tracking-widest uppercase"
          >
            Update Links
          </h1>
        </div>
        <div className="max-w-md mx-auto bg-white dark:bg-gray-800 p-6 rounded-lg shadow-md">
          {isLoading ? (
            <div className="flex justify-center items-center min-h-[200px]">
              <Loader color={currentColor} />
            </div>
          ) : (
            <>
              <div className="mb-4">
                <label className="block text-sm font-medium mb-2">
                  Discord Link
                </label>
                <input
                  type="url"
                  name="discord"
                  value={links.discord}
                  onChange={handleInputChange}
                  className="w-full p-2 border rounded text-black"
                  placeholder="Enter Discord link"
                />
              </div>
              <div className="mb-4">
                <label className="block text-sm font-medium mb-2">
                  Telegram Link
                </label>
                <input
                  type="url"
                  name="telegram"
                  value={links.telegram}
                  onChange={handleInputChange}
                  className="w-full p-2 border rounded text-black"
                  placeholder="Enter Telegram link"
                />
              </div>
              <div className="mb-4">
                <label className="block text-sm font-medium mb-2">
                  Trading View Link
                </label>
                <input
                  type="url"
                  name="tradingView"
                  value={links.tradingView}
                  onChange={handleInputChange}
                  className="w-full p-2 border rounded text-black"
                  placeholder="Enter TradingView link"
                />
              </div>
              <div className="flex justify-end space-x-3">
                <button
                  onClick={handleUpdateLinks}
                  className="px-4 py-2 bg-blue-500 text-white rounded"
                  disabled={isLoading}
                  style={{ backgroundColor: currentColor }}
                >
                  {isLoading ? "Updating..." : "Update Links"}
                </button>
              </div>
            </>
          )}
          {message && <p className="mt-4 text-center">{message}</p>}
        </div>
      </div>
    </section>
  );
};

export default UpdateLinks;

import React, { useState } from "react";
import { Box, Tabs, Tab, Paper } from "@mui/material";
import { styled } from "@mui/system";
import WhatIsEFTS from "./WhatIsEFTS";
import BecomeACEO from "./BecomeACEO";
import AboutUs from "./AboutUs";
import axios from "axios";
import { baseUrl } from "./../data/constant";
import AffiliateProgram from "./AffiliateProgram";
import { toast } from "react-toastify";

// Style for the main tabs (Products, Resources)
const StyledTabs = styled(Tabs)(({ theme }) => ({
  marginBottom: theme.spacing(2),
}));

// Style for the main tabs (Products, Resources)
const StyledTab = styled(Tab)(({ theme }) => ({
  fontWeight: "bold",
  textTransform: "uppercase",
  fontSize: "16px",
  color: "#1976d2", // Blue color for tabs
  '&.Mui-selected': {
    color: "#1565c0", // Darker blue when selected
  },
  '&:hover': {
    color: "#1565c0", // Darker blue on hover
  },
}));

// Style for the child tabs (What is EFTS, Become a CEO, About Us, Affiliate Program)
const StyledChildTab = styled(Tab)(({ theme }) => ({
  fontWeight: "bold",
  textTransform: "uppercase",
  fontSize: "14px",
  backgroundColor: "#f0f0f0", // Light background for child tabs
  color: "#333", // Dark text for better contrast
  borderRadius: "4px",
  '&.Mui-selected': {
    backgroundColor: "#1976d2", // Blue background for selected tab
    color: "white", // White text when selected
  },
  '&:hover': {
    backgroundColor: "#e0e0e0", // Light gray on hover
  },
}));

const TabPanel = (props) => {
  const { children, value, index, ...other } = props;
  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`tabpanel-${index}`}
      aria-labelledby={`tab-${index}`}
      {...other}
    >
      {value === index && <Box sx={{ p: 3 }}>{children}</Box>}
    </div>
  );
};

const saveContentToBackend = async (payload) => {
  const token = localStorage.getItem("token");
  try {
    const response = await axios.post(
      `${baseUrl}/admin/cms`,
      payload,
      {
        headers: {
          Authorization: `Bearer ${token}`,
          "Content-Type": "application/json",
        },
      }
    );
    toast("Content saved successfully!");
  } catch (error) {
    console.error("Error saving content:", error);
    alert("Failed to save content.");
  }
};

const ManageContent = () => {
  const [mainTabValue, setMainTabValue] = useState(0);
  const [subTabValue, setSubTabValue] = useState(0);

  const handleMainTabChange = (event, newValue) => {
    setMainTabValue(newValue);
    setSubTabValue(0);
  };

  const handleSubTabChange = (event, newSubValue) => {
    setSubTabValue(newSubValue);
  };

  return (
    <Box sx={{ width: "100%", padding: 3 }}>
      <StyledTabs
        value={mainTabValue}
        onChange={handleMainTabChange}
        aria-label="Main tabs"
      >
        <StyledTab label="Products" />
        <StyledTab label="Resources" />
      </StyledTabs>

      <TabPanel value={mainTabValue} index={0}>
        <StyledTabs
          value={subTabValue}
          onChange={handleSubTabChange}
          aria-label="Product sub tabs"
        >
          <StyledChildTab label="What is EFTS" />
          <StyledChildTab label="Become a CEO" />
        </StyledTabs>

        <Paper elevation={3} sx={{ padding: 2, borderRadius: 2, marginTop: 2 }}>
          <TabPanel value={subTabValue} index={0}>
            <WhatIsEFTS onSave={saveContentToBackend} />
          </TabPanel>
          <TabPanel value={subTabValue} index={1}>
            <BecomeACEO onSave={saveContentToBackend} />
          </TabPanel>
        </Paper>
      </TabPanel>

      <TabPanel value={mainTabValue} index={1}>
        <StyledTabs
          value={subTabValue}
          onChange={handleSubTabChange}
          aria-label="Resources sub tabs"
        >
          <StyledChildTab label="Affiliate Program" />
        </StyledTabs>

        <Paper elevation={3} sx={{ padding: 2, borderRadius: 2, marginTop: 2 }}>
          <TabPanel value={subTabValue} index={0}>
            <AffiliateProgram onSave={saveContentToBackend} />
          </TabPanel>
        </Paper>
      </TabPanel>
    </Box>
  );
};

export default ManageContent;

import React, { useState } from "react";
import { useStateContext } from "../contexts/ContextProvider";
import axios from "axios";
import { baseUrl } from "../data/constant";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";

const CreateGuides = () => {
  const navigate = useNavigate();
  const { currentColor } = useStateContext();
  const [newGuide, setNewGuide] = useState({
    title: "",
    details: "",
    videos: [],
  });
  const [isLoading, setIsLoading] = useState(false);
  const [message, setMessage] = useState("");

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setNewGuide((prev) => ({ ...prev, [name]: value }));
  };

  const handleVideoDescriptionChange = (e, index) => {
    const { value } = e.target;
    const updatedVideos = [...newGuide.videos];
    updatedVideos[index].description = value;
    setNewGuide((prev) => ({
      ...prev,
      videos: updatedVideos,
    }));
  };

  const handleFileChange = (e) => {
    setNewGuide((prev) => ({
      ...prev,
      videos: Array.from(e.target.files)?.map((file) => ({
        url: URL.createObjectURL(file),
        file,
        description: "",
      })),
    }));
  };

  const createGuide = async () => {
    setIsLoading(true);
    setMessage("");
    try {
      const token = localStorage.getItem("token");
      const formData = new FormData();
      formData.append("title", newGuide.title);
      formData.append("details", newGuide.details);

      newGuide.videos.forEach((video) => {
        formData.append("files", video.file);
        formData.append("descriptions", video.description);
      });

      const { data } = await axios.post(
        `${baseUrl}/admin/create-guide`,
        formData,
        {
          headers: {
            "Content-Type": "multipart/form-data",
            Authorization: `Bearer ${token}`,
          },
        }
      );

      if (data.success) {
        toast.success("Guide created successfully!");
        navigate("/view-guides");
      } else {
        toast.error("Failed to create guide. Please try again.");
      }
    } catch (error) {
      console.error("Error creating guide:", error);
      toast.error("An error occurred. Please try again.");
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <section className="dark:text-white-shade text-black-shade body-font">
      <div className="container px-5 py-20 md:py-5 mx-auto">
        <div className="flex justify-between items-center w-full mb-10">
          <h1
            style={{
              color: currentColor,
            }}
            className="text-xl md:text-4xl font-medium title-font tracking-widest uppercase"
          >
            Create Guide
          </h1>
          <button
            style={{ backgroundColor: currentColor }}
            onClick={() => setNewGuide({ title: "", details: "", videos: [] })}
            className="py-2 px-5 rounded-md text-white"
          >
            Add Guide
          </button>
        </div>
        <div className="flex flex-wrap -m-4">
          <div className="p-4 w-full lg:w-1/2">
            <form
              className="bg-white dark:bg-gray-800 p-6 rounded-lg shadow-md"
              onSubmit={(e) => {
                e.preventDefault();
                createGuide();
              }}
            >
              <div className="mb-4">
                <label className="block text-sm font-medium mb-2">Title</label>
                <input
                  type="text"
                  name="title"
                  value={newGuide.title}
                  onChange={handleInputChange}
                  required
                  className="w-full p-2 border rounded text-black"
                  placeholder="Guide Title"
                />
              </div>
              <div className="mb-4">
                <label className="block text-sm font-medium mb-2">
                  Details
                </label>
                <textarea
                  name="details"
                  value={newGuide.details}
                  onChange={handleInputChange}
                  className="w-full p-2 border rounded text-black"
                  placeholder="Guide Details"
                />
              </div>
              <div className="mb-4">
                <label className="block text-sm font-medium mb-2">Videos</label>
                <input
                  type="file"
                  name="videos"
                  multiple
                  accept="video/mp4"
                  onChange={handleFileChange}
                  className="w-full p-2 border rounded text-black"
                />
                {newGuide.videos?.length > 0 && (
                  <div className="mt-4">
                    <h4 className="text-sm font-semibold text-gray-800">
                      Video Previews:
                    </h4>
                    <ul className="list-none">
                      {newGuide.videos.map((video, idx) => (
                        <li key={idx} className="my-2">
                          <video
                            controls
                            className="w-full h-auto"
                            src={video.url}
                          />
                          <h2>Video Description</h2>
                          <input
                            type="text"
                            value={video.description}
                            onChange={(e) =>
                              handleVideoDescriptionChange(e, idx)
                            }
                            placeholder="Add video description"
                            className="w-full mt-2 p-2 border rounded text-black"
                          />
                        </li>
                      ))}
                    </ul>
                  </div>
                )}
              </div>
              <button
                type="submit"
                style={{ backgroundColor: currentColor }}
                className="py-2 px-4 text-white rounded"
                disabled={isLoading}
              >
                {isLoading ? "Uploading..." : "Create Guide"}
              </button>
            </form>
            {message && <p className="mt-4 text-center">{message}</p>}
          </div>
        </div>
      </div>
    </section>
  );
};

export default CreateGuides;

import React, { useEffect, useState } from "react";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  Button,
  Pagination,
} from "@mui/material";
import moment from "moment";
import { useStateContext } from "../contexts/ContextProvider";
import axios from "axios";
import { useAuthContext } from "../contexts/AuthContext";
import { baseUrl } from "../data/constant";
import Loader from "./Loader";

const SubscribersTable = () => {
  const { currentColor } = useStateContext();
  const [isLoading, setIsLoading] = useState(false);
  const { token } = useAuthContext();
  const [subscribers, setSubscribers] = useState([]);
  const [page, setPage] = useState(1);
  const [pageSize, setPageSize] = useState(10);
  const [totalCount, setTotalCount] = useState(0);

  const fetchSubscribers = async () => {
    setIsLoading(true);
    try {
      const { data } = await axios.get(`${baseUrl}/admin/list-subscribers`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
        params: {
          page,
          pageSize,
        },
      });
      if (data.subscribers) {
        setSubscribers(data.subscribers);
        setTotalCount(data.totalCount); // Set the total number of subscribers
      }
    } catch (error) {
      console.error("Failed to fetch users:", error);
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    fetchSubscribers();
  }, [page, pageSize]); // Refetch when page or pageSize changes

  const handlePageChange = (event, value) => {
    setPage(value);
  };

  return (
    <div className="p-6 bg-gray-50">
      <h1
        style={{
          color: currentColor,
        }}
        className="mb-6 text-xl md:text-4xl font-medium title-font tracking-widest uppercase"
      >
        Subscribers
      </h1>
      {isLoading ? (
        <Loader /> // Show loader while fetching users
      ) : (
        <>
          <TableContainer component={Paper} className="shadow-md rounded-lg">
            <Table aria-label="subscribers table">
              <TableHead className="bg-gray-200">
                <TableRow>
                  <TableCell className="font-bold text-gray-700 uppercase">
                    Plan Name
                  </TableCell>
                  <TableCell className="font-bold text-gray-700 uppercase">
                    User Name
                  </TableCell>
                  <TableCell className="font-bold text-gray-700 uppercase">
                    User Email
                  </TableCell>
                  <TableCell className="font-bold text-gray-700 uppercase">
                    Type
                  </TableCell>
                  <TableCell className="font-bold text-gray-700 uppercase">
                    Price
                  </TableCell>
                  <TableCell className="font-bold text-gray-700 uppercase">
                    Start Date
                  </TableCell>
                  <TableCell className="font-bold text-gray-700 uppercase">
                    End Date
                  </TableCell>
                  <TableCell className="font-bold text-gray-700 uppercase">
                    Alerts Used
                  </TableCell>
                  <TableCell className="font-bold text-gray-700 uppercase">
                    Stripe Status
                  </TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {subscribers?.map((subscriber) => (
                  <TableRow key={subscriber._id}>
                    <TableCell>{subscriber.planName}</TableCell>
                    <TableCell className="capitalize">
                      {subscriber?.userId?.firstName +
                        " " +
                        subscriber?.userId?.lastName}
                    </TableCell>
                    <TableCell className="capitalize">
                      {subscriber?.userId?.email}
                    </TableCell>
                    <TableCell className="capitalize">
                      {subscriber.type}
                    </TableCell>
                    <TableCell>${subscriber.price.toFixed(2)}</TableCell>
                    <TableCell>
                      {moment(subscriber.startDate).format("MM/DD/YYYY")}
                    </TableCell>
                    <TableCell>
                      {moment(subscriber.endDate).format("MM/DD/YYYY")}
                    </TableCell>
                    <TableCell>
                      {subscriber.usedAlerts}/{subscriber.alerts}
                    </TableCell>
                    <TableCell>
                      <span
                        className={`px-2 py-1 rounded text-white ${
                          subscriber.stripeStatus === "active"
                            ? "bg-green-500"
                            : "bg-red-500"
                        }`}
                      >
                        {subscriber.stripeStatus}
                      </span>
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>

          {/* Pagination */}
          <Pagination
            count={Math.ceil(totalCount / pageSize)}
            page={page}
            onChange={handlePageChange}
            color="primary"
            sx={{ marginTop: 3 }}
          />
        </>
      )}
    </div>
  );
};

export default SubscribersTable;

import React, { useState, useEffect } from "react";
import { Box, TextField, Button, Typography, Paper, Grid, IconButton, CircularProgress, Card, CardContent } from "@mui/material";
import DeleteIcon from "@mui/icons-material/Delete";
import axios from "axios";
import { baseUrl } from "../data/constant"; // Replace with your actual base URL

const AffiliateProgram = ({ onSave }) => {
  const [affiliatePrograms, setAffiliatePrograms] = useState([]);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const fetchAffiliatePrograms = async () => {
      try {
        const token = localStorage.getItem("token");
        const { data } = await axios.get(`${baseUrl}/admin/cms/affiliate-programs`, {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        });

        if (data && data.data) {
          setAffiliatePrograms(data.data.content || []);
        }
      } catch (error) {
        console.error("Error fetching affiliate programs:", error);
      } finally {
        setLoading(false);
      }
    };

    fetchAffiliatePrograms();
  }, []);

  const handleAddAffiliateProgram = () => {
    setAffiliatePrograms([
      ...affiliatePrograms,
      { id: Date.now(), tagline: "", title: "", description: "", image: "" },
    ]);
  };

  const handleDeleteAffiliateProgram = (id) => {
    setAffiliatePrograms(affiliatePrograms.filter((program) => program.id !== id));
  };

  const handleAffiliateProgramChange = (id, field, value) => {
    setAffiliatePrograms(
      affiliatePrograms.map((program) =>
        program.id === id ? { ...program, [field]: value } : program
      )
    );
  };

  const handleImageUpload = (id, event) => {
    const file = event.target.files[0];
    if (file) {
      const reader = new FileReader();
      reader.onloadend = () => {
        const base64Image = reader.result.split(",")[1]; // Get the base64 part only
        handleAffiliateProgramChange(id, "image", base64Image);
      };
      reader.readAsDataURL(file);
    }
  };

  const handleSave = () => {
    const payload = {
      pageName: "Affiliate Program",
      slug: "affiliate-programs",
      content: affiliatePrograms,
    };
    if (onSave) {
      onSave(payload);
    }
  };

  return (
    <Box sx={{ padding: 3 }}>
      <Paper elevation={3} sx={{ padding: 3, borderRadius: 2 }}>
        <Typography variant="h6" gutterBottom>
          Affiliate Programs
        </Typography>

        {loading ? (
          <Box sx={{ display: "flex", justifyContent: "center", alignItems: "center", minHeight: "200px" }}>
            <CircularProgress />
          </Box>
        ) : (
          <>
            <Grid container spacing={3} sx={{ marginBottom: 3 }}>
              {affiliatePrograms.map((program) => (
                <Grid item xs={12} sm={6} md={4} key={program.id}>
                  <Card sx={{ display: "flex", flexDirection: "column", boxShadow: 3, border: "1px solid #ccc" }}>
                    <CardContent>
                      <Typography variant="h6" gutterBottom>
                        {program.title || "Untitled Program"}
                      </Typography>

                      {/* Tagline */}
                      <TextField
                        label="Tagline"
                        value={program.tagline}
                        onChange={(e) => handleAffiliateProgramChange(program.id, "tagline", e.target.value)}
                        variant="outlined"
                        fullWidth
                        sx={{ marginBottom: 2 }}
                      />

                      {/* Title */}
                      <TextField
                        label="Title"
                        value={program.title}
                        onChange={(e) => handleAffiliateProgramChange(program.id, "title", e.target.value)}
                        variant="outlined"
                        fullWidth
                        sx={{ marginBottom: 2 }}
                      />

                      {/* Description */}
                      <TextField
                        label="Description"
                        value={program.description}
                        onChange={(e) =>
                          handleAffiliateProgramChange(program.id, "description", e.target.value)
                        }
                        variant="outlined"
                        multiline
                        rows={4}
                        fullWidth
                        sx={{ marginBottom: 2 }}
                      />

                      {/* Image Upload */}
                      <Typography variant="subtitle1">Upload Image</Typography>
                      {program.image && (
                        <img
                          src={`data:image/png;base64,${program.image}`}
                          alt="Affiliate Program"
                          className="h-32 w-32 sm:h-40 sm:w-40 lg:h-48 lg:w-48 mx-auto object-contain"
                        />
                      )}
                      <input
                        type="file"
                        accept="image/*"
                        onChange={(e) => handleImageUpload(program.id, e)}
                        style={{ marginTop: "8px" }}
                      />

                      {/* Delete Button */}
                      <IconButton
                        color="error"
                        onClick={() => handleDeleteAffiliateProgram(program.id)}
                        sx={{ marginTop: 2 }}
                      >
                        <DeleteIcon />
                      </IconButton>
                    </CardContent>
                  </Card>
                </Grid>
              ))}
            </Grid>

            {/* Add New Affiliate Program Button */}
            <Box sx={{ display: "flex", justifyContent: "center", marginTop: 2 }}>
              <Button
                variant="contained"
                color="primary"
                onClick={handleAddAffiliateProgram}
                sx={{ marginRight: 2 }}
              >
                Add New Affiliate Program
              </Button>

              {/* Save Button */}
              <Button
                variant="contained"
                color="success"
                sx={{ marginLeft: 2 }}
                onClick={handleSave}
              >
                Save Affiliate Programs
              </Button>
            </Box>
          </>
        )}
      </Paper>
    </Box>
  );
};

export default AffiliateProgram;

import React, { useEffect, useState } from "react";
import {
  Button,
  Checkbox,
  FormControlLabel,
  Typography,
  Box,
  Paper,
} from "@mui/material";
import { useStateContext } from "../contexts/ContextProvider";
import { baseUrl } from "../data/constant";
import axios from "axios";
import { useAuthContext } from "../contexts/AuthContext";
// import { io } from "socket.io-client";
import { toast } from "react-toastify";

const CreateNotification = () => {
  const { currentColor, currentMode } = useStateContext();
  const [notificationMessage, setNotificationMessage] = useState("");
  const [title, setTitle] = useState("");
  const [selectedUsers, setSelectedUsers] = useState([]);
  const { token } = useAuthContext();
  const [users, setUsers] = useState([]);

  const [error, setError] = useState("");

  useEffect(() => {
    fetchOnlineUsers();
  }, []);

  // useEffect(() => {
  //   const socket = io("http://localhost:3002", {
  //     auth: {
  //       token: token,
  //     },
  //   });

  //   socket.on("admin-notification", (message) => {
  //     alert(message);
  //   });

  //   return () => {
  //     socket.disconnect();
  //   };
  // }, []);

  async function fetchOnlineUsers() {
    try {
      const { data } = await axios.get(`${baseUrl}/admin/get-online-users`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      if (data.users) {
        setUsers(data.users);
      }
    } catch (error) {
      console.error("Failed to fetch users:", error);
    }
  }

  async function sendNotificationToUsers() {
    try {
      const { data } = await axios.post(
        `${baseUrl}/admin/notify`,
        { message: notificationMessage, title, selectedUsers },
        {
          headers: {
            Authorization: `Bearer ${token}`,
            "Content-Type": "application/json",
          },
        }
      );

      if (data.success) {
        console.log("Notification sent successfully:", data.message);
      } else {
        console.error("Failed to send notification:", data.message);
      }
    } catch (error) {
      console.error("Failed to send notification:", error);
    }
  }

  const handleUserSelection = (userId) => {
    setSelectedUsers((prevSelectedUsers) =>
      prevSelectedUsers.includes(userId)
        ? prevSelectedUsers.filter((id) => id !== userId)
        : [...prevSelectedUsers, userId]
    );
  };

  const handleSendNotification = async () => {
    // Clear any previous error message
    setError("");

    // Check if both title and notification message are provided
    if (notificationMessage.trim() === "" || title.trim() === "") {
      setError("Both title and notification message are required.");
      return;
    }

    if (selectedUsers.length === 0) {
      toast.error("Please select at least one user.");
      return;
    }

    try {
      await sendNotificationToUsers();
      toast.success("Notification sent successfully!");
      setNotificationMessage("");
      setTitle("");
      setSelectedUsers([]);
    } catch (error) {
      console.error("Failed to send notification:", error);
      toast.error("Failed to send notification. Please try again.");
    }
  };

  const backgroundColor = currentMode === "Dark" ? "#20232a" : "#fafbfb";
  const textColor = currentMode === "Dark" ? "#fafbfb" : "#20232a";

  return (
    <Box
      className="flex justify-center items-center min-h-screen py-8"
      sx={{ backgroundColor }}
    >
      <Paper
        className="p-8 w-full max-w-lg rounded-lg shadow-lg"
        sx={{ backgroundColor, color: textColor }}
      >
        <div className="flex justify-between items-center w-full mb-10">
          <h1
            style={{
              color: currentColor,
            }}
            className="text-center text-xl md:text-4xl font-medium title-font tracking-widest uppercase"
          >
            Send Notification
          </h1>
        </div>

        {/* Title Input */}
        <textarea
          className="w-full p-4 border border-gray-300 rounded-lg mb-4"
          rows="1"
          value={title}
          onChange={(e) => setTitle(e.target.value)}
          placeholder="Enter Title"
          style={{
            backgroundColor: currentMode === "Dark" ? "#333" : "#fff",
            color: textColor,
          }}
        ></textarea>

        {/* Notification Message Input */}
        <textarea
          className="w-full p-4 border border-gray-300 rounded-lg mb-4"
          rows="4"
          value={notificationMessage}
          onChange={(e) => setNotificationMessage(e.target.value)}
          placeholder="Enter notification message"
          style={{
            backgroundColor: currentMode === "Dark" ? "#333" : "#fff",
            color: textColor,
          }}
        ></textarea>

        {/* Error message */}
        {error && (
          <Typography
            variant="body1"
            sx={{
              color: "red",
              marginBottom: 2,
              textAlign: "center",
            }}
          >
            {error}
          </Typography>
        )}

        <Typography variant="h6" className="mb-2" sx={{ color: currentColor }}>
          Select Online Users to Notify:
        </Typography>

        <div className="mb-4">
          {users && users.length > 0 ? (
            users.map((user) => (
              <FormControlLabel
                key={user.userId}
                control={
                  <Checkbox
                    checked={selectedUsers.includes(user.userId)}
                    onChange={() => handleUserSelection(user.userId)}
                    name={`user-${user.userId}`}
                    color="primary"
                  />
                }
                label={user.userName}
              />
            ))
          ) : (
            <Typography
              variant="body1"
              sx={{ textAlign: "center", color: "gray", marginTop: 2 }}
            >
              No users online.
            </Typography>
          )}
        </div>

        <Button
          onClick={handleSendNotification}
          className="w-full py-2 rounded-lg"
          sx={{
            backgroundColor: currentMode === "Dark" ? "#4caf50" : currentColor,
            color: currentMode === "Dark" ? "#ffffff" : "#ffffff",
            "&:hover": {
              backgroundColor: currentMode === "Dark" ? "#45a049" : "#0056b3",
            },
          }}
          variant="contained"
        >
          Send Notification to Selected Users
        </Button>
      </Paper>
    </Box>
  );
};

export default CreateNotification;

import React, { useState } from "react";
import { useFormik } from "formik";
import * as Yup from "yup";
import { useNavigate } from "react-router-dom";
import { useStateContext } from "../contexts/ContextProvider";
import { usePostContext } from "../contexts/PostContext";
import { useFetchContext } from "../contexts/FetchContext";
import { Button, CircularProgress } from "@mui/material";

const CreateClass = () => {
  const { currentColor } = useStateContext();
  const { PostClass } = usePostContext();
  const { categories } = useFetchContext();
  const [btnLoading, setBtnLoading] = useState(false);
  const [files, setFiles] = useState([]);
  const navigate = useNavigate();

  const handleFileChange = (e) => {
    const selectedFiles = Array.from(e.target.files);
    formik.setFieldValue("files", selectedFiles);
    setFiles(selectedFiles);
  };

  const handleRemoveFile = (fileName) => {
    const updatedFiles = files.filter((file) => file.name !== fileName);
    setFiles(updatedFiles);
    formik.setFieldValue("files", updatedFiles);
  };

  const validationSchema = Yup.object({
    name: Yup.string().required("Product name is required"),
    category_id: Yup.string().required("Category is required"),
    length: Yup.string().required("Length is required"),
    details: Yup.string().required("Details are required"),
    rating: Yup.string().required("Rating is required"),
    quantity: Yup.number()
      .required("Quantity is required")
      .positive("Quantity must be positive"),
    price: Yup.number()
      .required("Price is required")
      .positive("Price must be positive"),
    files: Yup.array()
      .min(1, "At least one file must be uploaded")
      .required("Files are required"),
  });

  const formik = useFormik({
    initialValues: {
      name: "",
      category_id: "",
      length: "",
      details: "",
      rating: "",
      quantity: "",
      price: "",
      files: [],
    },
    validationSchema,
    onSubmit: async (values) => {
      setBtnLoading(true);
      const formData = new FormData();
      Object.entries(values).forEach(([key, value]) => {
        if (key === "files") {
          value.forEach((file) => formData.append("files", file));
        } else {
          formData.append(key, value);
        }
      });

      await PostClass(formData);
      setBtnLoading(false);
      navigate("/products");
    },
  });

  return (
    <div className="bg-gray-50 dark:bg-gray-800 min-h-screen p-8">
      <div className="max-w-4xl mx-auto bg-white dark:bg-gray-900 shadow-lg rounded-lg p-6">
        <h1
          className="text-3xl md:text-4xl font-bold text-gray-800 dark:text-gray-100 mb-6 text-center"
          style={{ color: currentColor }}
        >
          Create Product
        </h1>
        <form onSubmit={formik.handleSubmit} className="space-y-6">
          {/* Name Field */}
          <div>
            <label
              htmlFor="name"
              className="block text-gray-600 dark:text-gray-300 mb-1"
            >
              Product Name
            </label>
            <input
              id="name"
              name="name"
              type="text"
              className="w-full rounded-lg border border-gray-300 dark:border-gray-700 bg-gray-50 dark:bg-gray-800 text-gray-900 dark:text-gray-100 focus:ring focus:ring-indigo-300 p-3"
              placeholder="Enter product name"
              value={formik.values.name}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
            />
            {formik.touched.name && formik.errors.name && (
              <p className="text-red-500 text-sm mt-1">{formik.errors.name}</p>
            )}
          </div>

          {/* Category Field */}
          <div>
            <label
              htmlFor="category_id"
              className="block text-gray-600 dark:text-gray-300 mb-1"
            >
              Category
            </label>
            <select
              id="category_id"
              name="category_id"
              className="w-full rounded-lg border border-gray-300 dark:border-gray-700 bg-gray-50 dark:bg-gray-800 text-gray-900 dark:text-gray-100 focus:ring focus:ring-indigo-300 p-3"
              value={formik.values.category_id}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
            >
              <option value="">Select a category</option>
              {categories?.map((category) => (
                <option key={category._id} value={category._id}>
                  {category.name}
                </option>
              ))}
            </select>
            {formik.touched.category_id && formik.errors.category_id && (
              <p className="text-red-500 text-sm mt-1">
                {formik.errors.category_id}
              </p>
            )}
          </div>

          {/* Other Fields */}
          {["length", "details", "rating", "quantity", "price"].map((field) => (
            <div key={field}>
              <label
                htmlFor={field}
                className="block text-gray-600 dark:text-gray-300 mb-1"
              >
                {field.charAt(0).toUpperCase() + field.slice(1)}
              </label>
              <input
                id={field}
                name={field}
                type={
                  field === "quantity" || field === "price" ? "number" : "text"
                }
                className="w-full rounded-lg border border-gray-300 dark:border-gray-700 bg-gray-50 dark:bg-gray-800 text-gray-900 dark:text-gray-100 focus:ring focus:ring-indigo-300 p-3"
                placeholder={`Enter ${field}`}
                value={formik.values[field]}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
              />
              {formik.touched[field] && formik.errors[field] && (
                <p className="text-red-500 text-sm mt-1">
                  {formik.errors[field]}
                </p>
              )}
            </div>
          ))}

          {/* File Upload */}
          <div>
            <label
              htmlFor="files"
              className="block text-gray-600 dark:text-gray-300 mb-1"
            >
              Upload Files
            </label>
            <input
              id="files"
              name="files"
              type="file"
              multiple
              onChange={handleFileChange}
              className="block w-full rounded-lg border border-gray-300 dark:border-gray-700 bg-gray-50 dark:bg-gray-800 text-gray-900 dark:text-gray-100 focus:ring focus:ring-indigo-300 p-3"
            />
            {formik.touched.files && formik.errors.files && (
              <p className="text-red-500 text-sm mt-1">{formik.errors.files}</p>
            )}
            <div className="mt-3 flex flex-wrap gap-2">
              {files.map((file, index) => (
                <div
                  key={`${file.name}-${index}`}
                  className="flex items-center gap-2 bg-gray-200 dark:bg-gray-700 p-2 rounded-lg"
                >
                  <span className="text-sm">{file.name}</span>
                  <button
                    type="button"
                    onClick={() => handleRemoveFile(file.name)}
                    className="text-red-500 hover:underline"
                  >
                    Remove
                  </button>
                </div>
              ))}
            </div>
          </div>

          {/* Submit Button */}
          <div className="text-right">
            <Button
              type="submit"
              variant="contained"
              disabled={btnLoading}
              sx={{ bgcolor: currentColor, color: "#fff" }}
            >
              {btnLoading ? (
                <CircularProgress size={24} color="inherit" />
              ) : (
                "Create"
              )}
            </Button>
          </div>
        </form>
      </div>
    </div>
  );
};

export default CreateClass;

import React, { useEffect, useState } from "react";
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Button,
  TextField,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  Grid,
  Typography,
  Paper,
  TableContainer,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
} from "@mui/material";
import { useFetchContext } from "../contexts/FetchContext";
import moment from "moment";
import { MdEdit } from "react-icons/md";
import ProposalTabs from "./Proposals";
import axios from "axios";
import { useAuthContext } from "../contexts/AuthContext";
import { baseUrl } from "../data/constant";
import { useStateContext } from "../contexts/ContextProvider";

const Projects = () => {
  const { currentColor } = useStateContext();
  const [currentPage, setCurrentPage] = useState(1);
  const [itemsPerPage] = useState(10);
  const [searchTerm, setSearchTerm] = useState("");
  const { projects, fetchProjects } = useFetchContext();
  const [openModal, setOpenModal] = useState(false);
  const [selectedProject, setSelectedProject] = useState(null);
  const [areaDetails, setAreaDetails] = useState([]);
  const { token } = useAuthContext();
  const handlePageChange = (page) => {
    setCurrentPage(page);
  };
  const handleSearchChange = (e) => {
    setSearchTerm(e.target.value);
    fetchProjects(e.target.value);
  };
  const handleCloseModal = () => {
    setOpenModal(false);
    setSelectedProject(null);
  };
  const fetchProjectAreas = async () => {
    const { data } = await axios.get(
      `${baseUrl}/project/get-products-with-areas-project/${selectedProject._id}`,
      {
        headers: { Authorization: `Bearer ${token}` },
      }
    );
    setAreaDetails(data.data || []);
  };
  useEffect(() => {
    if (selectedProject) {
      fetchProjectAreas();
    }
  }, [selectedProject]);
  const handleViewClick = (project) => {
    setSelectedProject(project);
    setOpenModal(true);
  };
  const handleStatusChange = (event) => {
    setSelectedProject((prevState) => ({
      ...prevState,
      status: event.target.value,
    }));
    let project = projects.find((x) => x._id == selectedProject._id);
    project.status = event.target.value;
    updateProjectStatus(project);
  };
  const updateProjectStatus = async (project) => {
    const { data } = await axios.put(
      `${baseUrl}/project/${project._id}`,
      project,
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );
    if (data.success) {
      fetchProjects();
    }
  };
  const getStatusColorClass = (status) => {
    switch (status) {
      case "Development":
        return "bg-yellow-200 text-yellow-800";
      case "Completed":
        return "bg-green-200 text-green-800";
      case "Designing":
        return "bg-red-200 text-red-800";
      default:
        return "bg-gray-100 text-gray-800";
    }
  };
  const totalPages = Math.ceil(projects?.length / itemsPerPage);
  const paginatedProjects = projects?.slice(
    (currentPage - 1) * itemsPerPage,
    currentPage * itemsPerPage
  );

  return (
    <section
      style={{
        color: "black",
      }}
    >
      <div className="container px-5 py-20 md:py-5 mx-auto">
        <div className="flex justify-between items-center w-full mb-10">
          <h1
            style={{
              color: currentColor,
            }}
            className="text-xl md:text-4xl font-medium title-font tracking-widest uppercase"
          >
            Projects
          </h1>
          <input
            type="text"
            placeholder="Search Projects..."
            value={searchTerm}
            onChange={handleSearchChange}
            className="border rounded px-4 py-2 focus:outline-none focus:ring-2 focus:ring-blue-500"
          />
        </div>

        <div className="overflow-x-auto shadow-lg rounded-lg border border-gray-300">
          <table className="min-w-full table-auto bg-white border-collapse">
            <thead className="bg-gray-100 text-gray-700">
              <tr>
                <th className="px-6 py-4 border-b text-center uppercase">ID</th>
                <th className="px-6 py-4 border-b text-center uppercase">
                  Project Name
                </th>
                <th className="px-6 py-4 border-b text-center uppercase">
                  Assigned To
                </th>
                <th className="px-6 py-4 border-b text-center uppercase">
                  Client Name
                </th>
                <th className="px-6 py-4 border-b text-center uppercase">
                  Status
                </th>
                <th className="px-6 py-4 border-b text-center uppercase">
                  Date
                </th>
                <th className="px-6 py-4 border-b text-center uppercase">
                  Actions
                </th>
              </tr>
            </thead>
            <tbody>
              {paginatedProjects?.map((project) => (
                <tr key={project.project_uuid}>
                  <td className="px-6 py-4 border-b text-center">
                    {project.project_uuid}
                  </td>
                  <td className="px-6 py-4 border-b text-center">
                    {project.name}
                  </td>
                  <td className="px-6 py-4 border-b text-center">
                    {project.assigned_to}
                  </td>
                  <td className="px-6 py-4 border-b text-center">
                    {project.client_name}
                  </td>
                  <td
                    className={`${getStatusColorClass(
                      project.status
                    )} hover:bg-gray-50 transition-colors duration-200 text-center`}
                  >
                    {project.status}
                  </td>
                  <td className="px-6 py-4 border-b text-center">
                    {moment(project.createdAt).format("MM-DD-YYYY")}
                  </td>
                  <td className="px-6 py-4 border-b text-center">
                    <button
                      className="bg-blue-500 text-white px-4 py-2 rounded hover:bg-blue-700"
                      onClick={() => handleViewClick(project)}
                    >
                      View / Edit Details
                    </button>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
        <div className="flex justify-center items-center mt-4">
          {Array.from({ length: totalPages }, (_, index) => (
            <button
              key={index}
              className={`px-4 py-2 mx-1 rounded ${
                currentPage === index + 1
                  ? "bg-blue-500 text-white"
                  : "bg-gray-200 text-black"
              }`}
              onClick={() => handlePageChange(index + 1)}
            >
              {index + 1}
            </button>
          ))}
        </div>
      </div>
      <Dialog
        open={openModal}
        onClose={handleCloseModal}
        maxWidth="lg"
        fullWidth
      >
        <DialogTitle>Project Details</DialogTitle>
        <DialogContent>
          {selectedProject ? (
            <Grid container spacing={3}>
              <Grid item xs={12} sm={6}>
                <TextField
                  label="Project Name"
                  value={selectedProject?.name}
                  fullWidth
                  margin="normal"
                  InputProps={{
                    readOnly: true,
                  }}
                  sx={{
                    "& .MuiInputBase-input": {
                      color: "black",
                    },
                  }}
                />
              </Grid>

              <Grid item xs={12} sm={6}>
                <TextField
                  label="Assigned To"
                  value={selectedProject?.assigned_to}
                  fullWidth
                  margin="normal"
                  InputProps={{
                    readOnly: true,
                  }}
                  sx={{
                    "& .MuiInputBase-input": {
                      color: "black",
                    },
                  }}
                />
              </Grid>

              <Grid item xs={12} sm={6}>
                <TextField
                  label="Client Name"
                  value={selectedProject.client_name}
                  fullWidth
                  margin="normal"
                  InputProps={{
                    readOnly: true,
                    readOnly: true,
                  }}
                  sx={{
                    "& .MuiInputBase-input": {
                      color: "black",
                    },
                  }}
                />
              </Grid>

              <Grid item xs={12} sm={6}>
                <TextField
                  label="Client Contact"
                  value={selectedProject.client_contact}
                  fullWidth
                  margin="normal"
                  InputProps={{
                    readOnly: true,
                    readOnly: true,
                  }}
                  sx={{
                    "& .MuiInputBase-input": {
                      color: "black",
                    },
                  }}
                />
              </Grid>

              <Grid item xs={12} sm={6}>
                <TextField
                  label="Created At"
                  value={moment(selectedProject.createdAt).format("MM-DD-YYYY")}
                  fullWidth
                  margin="normal"
                  InputProps={{
                    readOnly: true,
                  }}
                  sx={{
                    "& .MuiInputBase-input": {
                      color: "black",
                    },
                  }}
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <TextField
                  label="Address"
                  value={`${selectedProject.location.address_line}, ${selectedProject.location.country}, ${selectedProject.location.city}, ${selectedProject.location.state}, ${selectedProject.location.zip}`}
                  fullWidth
                  margin="normal"
                  InputProps={{
                    readOnly: true,
                  }}
                  sx={{
                    "& .MuiInputBase-input": {
                      color: "black",
                    },
                  }}
                />
              </Grid>

              <Grid item xs={12} sm={6}>
                <FormControl fullWidth margin="normal">
                  <InputLabel>Status</InputLabel>
                  <Select
                    value={selectedProject.status}
                    onChange={handleStatusChange}
                    label="Status"
                    MenuProps={{
                      PaperProps: {
                        style: {
                          pointerEvents: "auto",
                        },
                      },
                    }}
                    sx={{
                      "& .MuiInputBase-input": {
                        color: "black",
                      },
                    }}
                  >
                    <MenuItem value="Designing">Designing</MenuItem>
                    <MenuItem value="Development">Development</MenuItem>
                    <MenuItem value="Completed">Completed</MenuItem>
                  </Select>
                </FormControl>
              </Grid>
            </Grid>
          ) : (
            <p>Loading...</p>
          )}
          <div className="areas-section">
            <Typography
              variant="h5"
              align="center"
              sx={{
                fontWeight: "bold",
                textTransform: "uppercase",
                mb: 3,
              }}
            >
              Areas
              <hr className="border-t-4 border-red-700 mt-2" />
            </Typography>

            {areaDetails?.length > 0 ? (
              areaDetails?.map((area) => (
                <Paper
                  key={area?._id}
                  elevation={3}
                  sx={{
                    marginBottom: 3,
                    padding: 3,
                    backgroundColor: "#fafafa",
                    borderRadius: "8px",
                  }}
                >
                  <Typography
                    variant="h6"
                    className="uppercase"
                    sx={{
                      fontWeight: "bold",
                      color: "primary.main",
                      marginBottom: 1,
                      color: "primary.main",
                    }}
                  >
                    {area?.name}
                  </Typography>

                  <TableContainer component={Paper} sx={{ marginTop: 2 }}>
                    <Table sx={{ minWidth: 650 }} aria-label="product table">
                      <TableHead>
                        <TableRow sx={{ backgroundColor: "#f1f1f1" }}>
                          <TableCell
                            className="uppercase"
                            align="center"
                            sx={{ fontWeight: "bold", color: "error.dark" }}
                          >
                            Product Name
                          </TableCell>
                          <TableCell
                            className="uppercase"
                            align="center"
                            sx={{ fontWeight: "bold", color: "error.dark" }}
                          >
                            Length
                          </TableCell>
                          <TableCell
                            className="uppercase"
                            align="center"
                            sx={{ fontWeight: "bold", color: "error.dark" }}
                          >
                            Price
                          </TableCell>
                          <TableCell
                            className="uppercase"
                            align="center"
                            sx={{ fontWeight: "bold", color: "error.dark" }}
                          >
                            Details
                          </TableCell>
                          <TableCell
                            className="uppercase"
                            align="center"
                            sx={{ fontWeight: "bold", color: "error.dark" }}
                          >
                            Quantity
                          </TableCell>
                          <TableCell
                            className="uppercase"
                            align="center"
                            sx={{ fontWeight: "bold", color: "error.dark" }}
                          >
                            Rating
                          </TableCell>
                        </TableRow>
                      </TableHead>
                      <TableBody>
                        {area?.products?.map((product) => (
                          <TableRow
                            key={product.product?._id}
                            sx={{
                              "&:hover": {
                                backgroundColor: "#f5f5f5",
                              },
                            }}
                          >
                            <TableCell align="center">
                              {product?.product?.name}
                            </TableCell>
                            <TableCell align="center">
                              {product.product?.length}
                            </TableCell>
                            <TableCell align="center">
                              {product.unit_price}
                            </TableCell>
                            <TableCell align="center">
                              {product.product?.details}
                            </TableCell>
                            <TableCell align="center">
                              {product.quantity}
                            </TableCell>
                            <TableCell align="center">
                              {product.product?.rating}
                            </TableCell>
                          </TableRow>
                        ))}
                      </TableBody>
                    </Table>
                  </TableContainer>
                </Paper>
              ))
            ) : (
              <Typography
                variant="h6"
                sx={{
                  textAlign: "center",
                  marginTop: 4,
                  color: "text.secondary",
                }}
              >
                No areas available for this project.
              </Typography>
            )}
          </div>

          <h1 className="font-bold text-center text-2xl uppercase">
            Proposals
            <hr className="border-t-4 border-red-700 mt-2" />
          </h1>
          <ProposalTabs projectId={selectedProject?._id} />
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCloseModal} color="primary">
            Close
          </Button>
        </DialogActions>
      </Dialog>
    </section>
  );
};

export default Projects;

import React, { useEffect, useState } from "react";
import {
  Card,
  CardContent,
  Typography,
  Grid,
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  TextField,
  CircularProgress,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
} from "@mui/material";
import axios from "axios";
import { baseUrl } from "../data/constant";
import Loader from "./Loader";
import { useStateContext } from "../contexts/ContextProvider";

const ProductsPlans = () => {
  const [products, setProducts] = useState([]);
  const [newPlanTitle, setNewPlanTitle] = useState("");
  const [newPlanBulletPoints, setNewPlanBulletPoints] = useState("");
  const [open, setOpen] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [updateOpen, setupdateOpen] = useState(false);
  const [newPlanName, setNewPlanName] = useState("");
  const [newDescription, setNewDescription] = useState("");
  const [alert, setAlert] = useState("");
  const [newPlanAmount, setNewPlanAmount] = useState("");
  const [updatePlanName, setupdatePlanName] = useState("");
  const [newPlanCurrency, setNewPlanCurrency] = useState("");
  const token = localStorage.getItem("token");
  const { currentColor } = useStateContext();
  const [interval, setInterval] = useState("month");
  const [currentProduct, setCurrentProduct] = useState(null);

  const handleIntervalChange = (event) => {
    setInterval(event.target.value);
  };
  useEffect(() => {
    fetchProducts();
  }, []);

  const fetchProducts = async () => {
    setIsLoading(true);
    try {
      const { data } = await axios.get(`${baseUrl}/admin/list-subscriptions`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      if (data.products) {
        setProducts(data.products);
      }
    } catch (error) {
      console.error("Failed to fetch products:", error);
    } finally {
      setIsLoading(false);
    }
  };

  const updateProductName = async () => {
    setIsLoading(true);
    try {
      await axios.put(
        `${baseUrl}/admin/update-subscription/${currentProduct.product.id}`,
        {
          name: updatePlanName,
        },
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
    } catch (error) {
      console.error("Failed to update product:", error);
    } finally {
      setIsLoading(false);
      setupdateOpen(false);
      fetchProducts();
    }
  };

  const addPlan = async () => {
    setIsLoading(true);

    try {
      const response = await axios.post(
        `${baseUrl}/admin/create-subscription`,
        {
          name: newPlanName,
          amount: newPlanAmount,
          currency: newPlanCurrency,
          interval,
          metadata: {
            interval,
            newDescription,
            alert,
            title: newPlanTitle,
            bullet_points: newPlanBulletPoints,
          },
        },
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );

      if (response.data.success) {
        fetchProducts();
        setOpen(false);
        setNewPlanName("");
        setNewPlanName("");
        setNewDescription("");
        setNewPlanCurrency("");
      }
    } catch (error) {
      console.error("Failed to add plan:", error);
    } finally {
      setIsLoading(false);
    }
  };

  const formatPrice = (amount, currency) => {
    return `${(amount / 100).toFixed(2)} ${currency.toUpperCase()}`;
  };

  const colorCombos = [{ backgroundColor: "#f5f5f5", textColor: "#212121" }];

  const getRandomColorCombo = () => {
    const randomIndex = Math.floor(Math.random() * colorCombos.length);
    return colorCombos[randomIndex];
  };

  return (
    <>
      <h1
        style={{
          color: currentColor,
        }}
        className="ml-5 text-xl md:text-4xl font-medium title-font tracking-widest uppercase"
      >
        Subscriptions
      </h1>

      {/* Add Plan Button */}
      <Button
        variant="contained"
        color="primary"
        onClick={() => setOpen(true)}
        sx={{ marginBottom: 3, float: "right" }}
      >
        Add Plan
      </Button>

      {/* Modal for Adding Plan */}
      <Dialog open={open} onClose={() => setOpen(false)}>
        <DialogTitle>Add a New Plan</DialogTitle>
        <DialogContent sx={{ paddingRight: 3 }}>
          <TextField
            label="Plan Name"
            fullWidth
            value={newPlanName}
            onChange={(e) => setNewPlanName(e.target.value)}
            sx={{ marginBottom: 2 }}
          />
          <TextField
            label="Plan Description"
            fullWidth
            value={newDescription}
            onChange={(e) => setNewDescription(e.target.value)}
            sx={{ marginBottom: 2 }}
          />
          <TextField
            label="Amount (in cents)"
            fullWidth
            type="number"
            value={newPlanAmount}
            onChange={(e) => setNewPlanAmount(e.target.value)}
            sx={{ marginBottom: 2 }}
          />
          <FormControl fullWidth sx={{ marginBottom: 2 }}>
            <InputLabel>Currency</InputLabel>
            <Select
              value={newPlanCurrency}
              onChange={(e) => setNewPlanCurrency(e.target.value)}
              label="Currency"
            >
              <MenuItem value="usd">USD</MenuItem>
              <MenuItem value="cad">CAD</MenuItem>
            </Select>
          </FormControl>
          <FormControl fullWidth>
            <InputLabel>Interval</InputLabel>
            <Select
              value={interval}
              onChange={handleIntervalChange}
              label="Interval"
            >
              <MenuItem value="month">Monthly</MenuItem>
              <MenuItem value="year">Yearly</MenuItem>
            </Select>
          </FormControl>

          {/* Title Input */}
          <TextField
            label="Title"
            fullWidth
            value={newPlanTitle}
            onChange={(e) => setNewPlanTitle(e.target.value)}
            sx={{ marginBottom: 2 }}
          />

          {/* alerts */}
          <TextField
            label="Alert"
            fullWidth
            type="number"
            value={alert}
            onChange={(e) => setAlert(e.target.value)}
            sx={{ marginBottom: 2 }}
          />

          <TextField
            label="Bullet Points"
            fullWidth
            placeholder="Give Point , separated like this Give access to copy trading , 24/7 support and active update"
            value={newPlanBulletPoints}
            onChange={(e) => setNewPlanBulletPoints(e.target.value)}
            sx={{ marginBottom: 2 }}
            multiline
            rows={4}
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setOpen(false)} color="secondary">
            Cancel
          </Button>
          <Button onClick={addPlan} color="primary" disabled={isLoading}>
            {isLoading ? <CircularProgress size={24} /> : "Add Plan"}
          </Button>
        </DialogActions>
      </Dialog>

      {isLoading ? (
        <Loader />
      ) : (
        <Box sx={{ flexGrow: 1, marginTop: 2, padding: 5 }}>
          <Grid container spacing={3}>
            {products.map((productData) => {
              const { backgroundColor, textColor } = getRandomColorCombo();

              return (
                <Grid item xs={12} sm={6} md={4} key={productData.product.id}>
                  <Card
                    sx={{
                      boxShadow: 12,
                      borderRadius: 3,
                      backgroundColor: backgroundColor,
                      color: textColor,
                      padding: 3,
                      transition: "0.3s ease-in-out",
                      "&:hover": {
                        transform: "scale(1.05)",
                        boxShadow: 16,
                      },
                    }}
                  >
                    <CardContent>
                      <Typography
                        variant="h5"
                        component="div"
                        gutterBottom
                        sx={{
                          fontWeight: "bold",
                          fontSize: "1.5rem",
                        }}
                      >
                        {productData.product.name || "Product Name"}
                      </Typography>

                      {/* Display Bullet Points with Circle Style */}
                      {productData.product.metadata &&
                        productData.product.metadata.bullet_points && (
                          <Box sx={{ marginBottom: 2 }}>
                            <Typography
                              variant="body1"
                              sx={{ fontWeight: "bold", marginBottom: 1 }}
                            >
                              Features:
                            </Typography>
                            <ul
                              style={{
                                listStyleType: "circle",
                                paddingLeft: "20px",
                              }}
                            >
                              {productData.product.metadata.bullet_points
                                .split(",")
                                .map((point, index) => (
                                  <li
                                    key={index}
                                    style={{ marginBottom: "5px" }}
                                  >
                                    {point.trim()}
                                  </li>
                                ))}
                            </ul>
                          </Box>
                        )}

                      {productData.plans.map((plan) => (
                        <Box key={plan.id} sx={{ marginBottom: 2 }}>
                          <Typography variant="h6" component="div" gutterBottom>
                            {formatPrice(plan.unit_amount, plan.currency)}
                          </Typography>
                          <Typography
                            variant="body1"
                            color="text.secondary"
                            paragraph
                          >
                            INTERVAL: {plan.recurring.interval.toUpperCase()} (
                            {plan.recurring.interval_count}{" "}
                            {plan.recurring.interval}s)
                          </Typography>

                          <Button
                            variant="contained"
                            color="error"
                            onClick={() => {
                              setupdateOpen(true);
                              setCurrentProduct(productData);
                            }}
                            sx={{
                              width: "100%",
                              marginTop: 2,
                            }}
                          >
                            Update
                          </Button>
                        </Box>
                      ))}
                    </CardContent>
                  </Card>
                </Grid>
              );
            })}
          </Grid>
        </Box>
      )}
      <Dialog open={updateOpen} onClose={() => setupdateOpen(false)}>
        <DialogTitle>Update Subscription Name</DialogTitle>
        <DialogContent>
          <TextField
            label="New Plan Name"
            fullWidth
            value={updatePlanName}
            onChange={(e) => setupdatePlanName(e.target.value)}
            sx={{ marginBottom: 2 }}
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setupdateOpen(false)} color="secondary">
            Cancel
          </Button>
          <Button
            onClick={() => updateProductName()}
            color="primary"
            disabled={isLoading}
          >
            {isLoading ? <CircularProgress size={24} /> : "Update Name"}
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};

export default ProductsPlans;

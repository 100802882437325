import React, { useState, useEffect } from "react";
import {
  Box,
  Grid,
  TextField,
  Typography,
  Paper,
  Button,
  IconButton,
} from "@mui/material";
import DeleteIcon from "@mui/icons-material/Delete";
import axios from "axios";
import { baseUrl } from "./../data/constant";

const BecomeACEO = ({ onSave }) => {
  const [heading, setHeading] = useState("");
  const [description, setDescription] = useState("");
  const [testimonials, setTestimonials] = useState([]);
  const slug = "become-a-ceo";

  // Fetch content on component mount
  useEffect(() => {
    const fetchContent = async () => {
      try {
        const token = localStorage.getItem("token");
        const { data } = await axios.get(`${baseUrl}/admin/cms/${slug}`, {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        });

        if (data.data) {
          const { heading, description, testimonials } = data.data.content;
          setHeading(heading || "");
          setDescription(description || "");
          setTestimonials(
            testimonials || [
              { id: Date.now(), name: "", description: "", image: "" },
            ]
          );
        }
      } catch (error) {
        console.error("Error fetching content:", error);
      }
    };

    fetchContent();
  }, [slug]);

  const handleAddTestimonial = () => {
    const newTestimonial = {
      id: Date.now(),
      name: "",
      description: "",
      image: "",
      date: new Date().toLocaleDateString(),
    };
    setTestimonials([...testimonials, newTestimonial]);
  };

  const handleDeleteTestimonial = (id) => {
    setTestimonials(
      testimonials.filter((testimonial) => testimonial.id !== id)
    );
  };

  const handleTestimonialChange = (id, field, value) => {
    setTestimonials(
      testimonials.map((testimonial) =>
        testimonial.id === id ? { ...testimonial, [field]: value } : testimonial
      )
    );
  };

  const handleImageChange = (id, e) => {
    const file = e.target.files[0];
    if (file) {
      const reader = new FileReader();
      reader.onloadend = () => {
        handleTestimonialChange(id, "image", reader.result);
      };
      reader.readAsDataURL(file);
    }
  };

  const handleSave = () => {
    const payload = {
      pageName: "Products",
      slug: "become-a-ceo",
      content: {
        // heading,
        // description,
        testimonials,
      },
    };
    if (onSave) onSave(payload);
  };

  return (
    <Box sx={{ padding: 3 }}>
      <Paper elevation={3} sx={{ padding: 3, borderRadius: 2 }}>
        <Typography variant="h6" component="h2" gutterBottom>
          Become a CEO Trader
        </Typography>

        <Grid container spacing={3}>
          {/* Heading Input */}
          {/* <Grid item xs={12}>
            <TextField
              label="Heading"
              value={heading}
              onChange={(e) => setHeading(e.target.value)}
              variant="outlined"
              fullWidth
              sx={{ marginBottom: 2 }}
            />
          </Grid> */}

          {/* Description Input */}
          {/* <Grid item xs={12}>
            <TextField
              label="Description"
              value={description}
              onChange={(e) => setDescription(e.target.value)}
              variant="outlined"
              multiline
              rows={4}
              fullWidth
              sx={{ marginBottom: 2 }}
            />
          </Grid> */}

          {/* Testimonials Section */}
          <Grid item xs={12}>
            <Typography className="uppercase" variant="h6" gutterBottom>
              Testimonials
            </Typography>
            <Button
              variant="contained"
              color="primary"
              onClick={handleAddTestimonial}
              sx={{ marginBottom: 2 }}
            >
              Add Testimonial
            </Button>

            {testimonials.map((testimonial) => (
              <Grid
                container
                spacing={3}
                key={testimonial.id}
                sx={{ marginBottom: 3 }}
              >
                <Grid item xs={12} sm={3}>
                  {/* Image Upload */}
                  <Box
                    sx={{
                      width: 80,
                      height: 80,
                      borderRadius: "50%",
                      overflow: "hidden",
                      border: "3px solid white",
                      backgroundColor: "#f5f5f5",
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                    }}
                  >
                    <img
                      src={testimonial.image}
                      alt="Testimonial"
                      style={{
                        width: "100%",
                        height: "100%",
                        objectFit: "cover",
                      }}
                    />
                  </Box>
                  <input
                    accept="image/*"
                    type="file"
                    onChange={(e) => handleImageChange(testimonial.id, e)}
                    style={{ display: "block", marginTop: "10px" }}
                  />
                </Grid>

                <Grid item xs={12} sm={4}>
                  <TextField
                    label="Name"
                    value={testimonial.name}
                    onChange={(e) =>
                      handleTestimonialChange(
                        testimonial.id,
                        "name",
                        e.target.value
                      )
                    }
                    variant="outlined"
                    fullWidth
                    sx={{ marginBottom: 2 }}
                  />
                  <TextField
                    label="Description"
                    value={testimonial.description}
                    onChange={(e) =>
                      handleTestimonialChange(
                        testimonial.id,
                        "description",
                        e.target.value
                      )
                    }
                    variant="outlined"
                    fullWidth
                    multiline
                    rows={3}
                    sx={{ marginBottom: 2 }}
                  />
                </Grid>

                <Grid item xs={12} sm={3}>
                  <Typography
                    variant="body2"
                    color="textSecondary"
                    sx={{ marginBottom: 2 }}
                  >
                    {testimonial.date}
                  </Typography>
                  <IconButton
                    color="error"
                    onClick={() => handleDeleteTestimonial(testimonial.id)}
                    sx={{ marginTop: 3 }}
                  >
                    <DeleteIcon />
                  </IconButton>
                </Grid>
              </Grid>
            ))}
          </Grid>
        </Grid>

        <Button
          variant="contained"
          color="success"
          sx={{ marginTop: 3 }}
          onClick={handleSave}
        >
          Save Content
        </Button>
      </Paper>
    </Box>
  );
};

export default BecomeACEO;

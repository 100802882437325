import React, { useEffect, useState } from "react";
import { useStateContext } from "../contexts/ContextProvider";
import Loading from "../components/Loading";
import GuideCard from "./GuideCard";
import { useNavigate } from "react-router-dom";
import { baseUrl } from "./../data/constant";
import axios from "axios";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import Loader from "./Loader";

const Guides = () => {
  const { currentColor, currentMode } = useStateContext();
  const navigate = useNavigate();

  const [staticGuides, setStaticGuides] = useState([]);
  const [loading, setLoading] = useState(true); // Track loading status

  // Fetch guides from API
  const fetchGuides = async () => {
    try {
      const token = localStorage.getItem("token");
      const { data } = await axios.get(`${baseUrl}/admin/guides`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      if (data.data) {
        setStaticGuides(data.data);
      }
    } catch (error) {
      console.error("Error fetching guides data:", error);
      toast.error("Failed to load guides. Please try again later.");
    } finally {
      setLoading(false); // Stop loading after API call completes
    }
  };

  useEffect(() => {
    fetchGuides();
  }, []);

  return (
    <section className="dark:text-white-shade text-black-shade body-font">
      <div className="container px-5 py-20 md:py-5 mx-auto">
        <div className="flex justify-between items-center w-full mb-10">
          <h1
            style={{
              color: currentColor,
            }}
            className="text-xl md:text-4xl font-medium title-font tracking-widest uppercase"
          >
            Guides
          </h1>
          <button
            style={{ backgroundColor: currentColor }}
            onClick={() => navigate("/create-guides")}
            className="py-2 px-5 rounded-md text-white"
          >
            Create Guide
          </button>
        </div>
        {loading ? (
          <div className="min-h-[20rem] w-full items-center justify-center flex">
            <Loader />
          </div>
        ) : staticGuides.length === 0 ? (
          <div className="min-h-[20rem] w-full items-center justify-center flex">
            <p>No guides available.</p>
          </div>
        ) : (
          <div className="flex flex-wrap -m-4">
            {staticGuides.map((guide) => (
              <GuideCard key={guide._id} guide={guide} />
            ))}
          </div>
        )}
      </div>
    </section>
  );
};

export default Guides;

import React, { useState, useEffect } from "react";
import {
  Box,
  Grid,
  Typography,
  Paper,
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Button,
  IconButton,
  Divider,
  TextField,
} from "@mui/material";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css"; // Import styles for the rich text editor
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import DeleteIcon from "@mui/icons-material/Delete";
import axios from "axios";
import { baseUrl } from "./../data/constant";

const WhatIsEFTS = ({ onSave }) => {
  // const [title, setTitle] = useState("");
  // const [subheading, setSubheading] = useState("");
  // const [description, setDescription] = useState("");
  const [faqs, setFaqs] = useState([]);
  const slug = "what-is-efts";

  useEffect(() => {
    const fetchContent = async () => {
      try {
        const token = localStorage.getItem("token");
        const { data } = await axios.get(`${baseUrl}/admin/cms/${slug}`, {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        });

        if (data.data) {
          const { faqs } = data.data.content;
          // setTitle(heading || "");
          // setSubheading(subheading || "");
          // setDescription(description || "");
          setFaqs(faqs || []);
        }
      } catch (error) {
        console.error("Error fetching content:", error);
      }
    };

    if (slug) {
      fetchContent();
    }
  }, [slug]);

  const handleAddFAQ = () => {
    const newFaq = { id: Date.now(), question: "", answer: "" };
    setFaqs([...faqs, newFaq]);
  };

  const handleDeleteFAQ = (id) => {
    setFaqs(faqs.filter((faq) => faq.id !== id));
  };

  const handleFAQChange = (id, field, value) => {
    setFaqs(
      faqs.map((faq) => (faq.id === id ? { ...faq, [field]: value } : faq))
    );
  };

  const handleSave = async () => {
    const payload = {
      pageName: "Products",
      slug: "what-is-efts",
      content: {
        // heading: title,
        // subheading,
        // description,
        faqs,
      },
    };

    try {
      if (onSave) {
        await onSave(payload);
      }
    } catch (error) {
      console.error("Error saving content:", error);
      alert("Failed to save content.");
    }
  };

  return (
    <Box sx={{ padding: 3 }}>
      <Paper elevation={3} sx={{ padding: 3, borderRadius: 2 }}>
        <Typography variant="h6" component="h2" gutterBottom>
          What is EFTS?
        </Typography>

        <Grid container spacing={3}>
          {/* <Grid item xs={12}>
            <Typography variant="body1" gutterBottom>
              Title
            </Typography>
            <ReactQuill
              value={title}
              onChange={(value) => setTitle(value)}
              theme="snow"
              placeholder="Enter title..."
              style={{ height: "150px", marginBottom: "16px" }}
            />
          </Grid> */}

          {/* Subheading */}
          {/* <Grid item xs={12}>
            <Typography variant="body1" gutterBottom>
              Subheading
            </Typography>
            <ReactQuill
              value={subheading}
              onChange={(value) => setSubheading(value)}
              theme="snow"
              placeholder="Enter subheading..."
              style={{ height: "150px", marginBottom: "16px" }}
            />
          </Grid> */}

          {/* Description */}
          {/* <Grid item xs={12}>
            <Typography variant="body1" gutterBottom>
              Description
            </Typography>
            <ReactQuill
              value={description}
              onChange={(value) => setDescription(value)}
              theme="snow"
              placeholder="Enter description..."
              style={{ height: "200px", marginBottom: "16px" }}
            />
          </Grid> */}

          {/* FAQs */}
          <Grid item xs={12}>
            <Typography variant="h6" gutterBottom>
              Frequently Asked Questions
            </Typography>
            <Divider sx={{ marginBottom: 2 }} />
            {faqs.map((faq) => (
              <Accordion key={faq.id} sx={{ marginBottom: 2 }}>
                <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                  <Typography variant="body1" sx={{ fontWeight: "bold" }}>
                    {faq.question || "New FAQ"}
                  </Typography>
                </AccordionSummary>
                <AccordionDetails>
                  <Grid container spacing={2}>
                    <Grid item xs={12}>
                      <TextField
                        label="Question"
                        value={faq.question}
                        onChange={(e) =>
                          handleFAQChange(faq.id, "question", e.target.value)
                        }
                        variant="outlined"
                        fullWidth
                        sx={{ marginBottom: 2 }}
                      />
                    </Grid>
                    <Grid item xs={12}>
                      <TextField
                        label="Answer"
                        value={faq.answer}
                        onChange={(e) =>
                          handleFAQChange(faq.id, "answer", e.target.value)
                        }
                        variant="outlined"
                        multiline
                        rows={2}
                        fullWidth
                        sx={{ marginBottom: 2 }}
                      />
                    </Grid>
                    <Grid item>
                      <IconButton
                        onClick={() => handleDeleteFAQ(faq.id)}
                        color="error"
                        sx={{
                          "&:hover": {
                            backgroundColor: "rgba(255, 0, 0, 0.1)",
                          },
                        }}
                      >
                        <DeleteIcon />
                      </IconButton>
                    </Grid>
                  </Grid>
                </AccordionDetails>
              </Accordion>
            ))}
            <Button
              variant="contained"
              color="primary"
              onClick={handleAddFAQ}
              sx={{ marginTop: 2 }}
            >
              Add New FAQ
            </Button>
          </Grid>
        </Grid>

        <Button
          variant="contained"
          color="success"
          sx={{ marginTop: 3 }}
          onClick={handleSave}
        >
          Save Content
        </Button>
      </Paper>
    </Box>
  );
};

export default WhatIsEFTS;

import React, { useEffect, useState } from "react";
import { BsCurrencyDollar, BsPersonCheckFill } from "react-icons/bs";
import { FiLink, FiUsers } from "react-icons/fi";
import { MdNotificationsActive } from "react-icons/md";
import { useAuthContext } from "../contexts/AuthContext";
import { baseUrl } from "../data/constant";
import axios from "axios";
import { CircularProgress } from "@mui/material"; // Material-UI loader

const Ecommerce = () => {
  const { token } = useAuthContext();
  const [stats, setStats] = useState(null); // Initially null to indicate loading
  const [loading, setLoading] = useState(true);

  const fetchStats = async () => {
    try {
      const { data } = await axios.get(`${baseUrl}/admin/stats`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      if (data.data) {
        setStats(data.data);
      }
    } catch (error) {
      console.error("Error fetching data:", error);
    } finally {
      setLoading(false); // Loading complete
    }
  };

  useEffect(() => {
    fetchStats();
  }, []);

  const statCards = [
    {
      icon: <BsCurrencyDollar />,
      value: stats?.subscriptions_sale
        ? `$${stats.subscriptions_sale.toFixed(2)}`
        : null,
      title: "Subscriptions Sale",
      color: "bg-green-500",
    },
    {
      icon: <FiUsers />,
      value: stats?.subscriptions ?? null,
      title: "Total Subscriptions",
      color: "bg-blue-500",
    },
    {
      icon: <BsPersonCheckFill />,
      value: stats?.subscribers ?? null,
      title: "Subscribers",
      color: "bg-purple-500",
    },
    {
      icon: <FiUsers />,
      value: stats?.users ?? null,
      title: "Total Users",
      color: "bg-teal-500",
    },
    {
      icon: <FiUsers />,
      value: stats?.online_users ?? null,
      title: "Online Users",
      color: "bg-orange-500",
    },
    {
      icon: <MdNotificationsActive />,
      value: stats?.notifications_sent ?? null,
      title: "Notifications Sent",
      color: "bg-yellow-500",
    },
    {
      icon: <FiUsers />,
      value: stats?.referrals_generated ?? null,
      title: "Referrals Generated",
      color: "bg-pink-500",
    },
    {
      icon: <FiUsers />,
      value: stats?.people_came_via_referrals ?? null,
      title: "People Via Referrals",
      color: "bg-indigo-500",
    },
    {
      icon: <FiUsers />,
      value: stats?.guides ?? null,
      title: "Guides Created",
      color: "bg-red-500",
    },
    {
      icon: <FiUsers />,
      value: stats?.blogs_generated ?? null,
      title: "Blogs Generated",
      color: "bg-gray-500",
    },
    {
      icon: <FiLink />,
      value: stats?.social_links_generated ?? null,
      title: "Social Links",
      color: "bg-red-500",
    },
  ];

  return (
    <div className="mt-6 mx-auto container">
      <div className="flex flex-wrap justify-center gap-6">
        {statCards.map((item, index) => (
          <div
            key={index}
            className={`w-60 h-40 ${item.color} text-white p-4 rounded-2xl shadow-lg flex flex-col items-center justify-center text-center`}
          >
            <div className="text-3xl mb-3">{item.icon}</div>
            {loading || item.value === null ? (
              <CircularProgress size={24} className="text-white" />
            ) : (
              <>
                <p className="text-lg font-bold">{item.value}</p>
                <p className="text-sm">{item.title}</p>
              </>
            )}
          </div>
        ))}
      </div>
    </div>
  );
};

export default Ecommerce;

import React from "react";

const GuideCard = ({ guide }) => {
  const { title, details, videos, createdAt } = guide;

  return (
    <div className="p-4 w-full lg:w-1/2">
      <div className="border-solid border-black border-spacing-5 p-5 h-full bg-white dark:bg-gray-800 border dark:border-gray-700 border-gray-200 rounded-lg overflow-hidden shadow-md">
        <div className="p-6">
          <h2 className="text-lg font-medium text-gray-900 dark:text-white mb-2">
            {title}
          </h2>
          <p className="leading-relaxed text-base text-gray-700 dark:text-gray-300 mb-4">
            {details || "No details provided"}
          </p>
          {videos?.length > 0 && (
            <div className="mb-4">
              <h3 className="text-sm font-semibold text-gray-800 dark:text-gray-400">
                Videos:
              </h3>
              <ul className="list-disc list-inside">
                {videos.map((video, idx) => (
                  <li key={idx} className="text-sm text-blue-500 mt-3">
                    <a
                      href={video.url}
                      target="_blank"
                      rel="noopener noreferrer"
                      className="hover:underline"
                    >
                      {video.description || `Video ${idx + 1}`}
                    </a>
                    <div className="mt-2">
                      <video
                        controls
                        className="w-full h-auto"
                        src={video.url}
                      />
                    </div>
                  </li>
                ))}
              </ul>
            </div>
          )}
          <p className="text-xs text-gray-500 dark:text-gray-400">
            Date: {new Date(createdAt).toLocaleDateString()}
          </p>
        </div>
      </div>
    </div>
  );
};

export default GuideCard;

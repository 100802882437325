// Loader.js
import React from "react";
import CircularProgress from "@mui/material/CircularProgress";

const Loader = () => {
  return (
    <div className="flex justify-center items-center min-h-screen bg-opacity-50 bg-gray-800 absolute top-0 left-0 right-0 bottom-0 z-50">
      <CircularProgress size={60} color="primary" />
    </div>
  );
};

export default Loader;

import React, { useEffect, useState } from "react";
import {
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Typography,
  Button,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  Box,
  Divider,
} from "@mui/material";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { useStateContext } from "../contexts/ContextProvider";
import { useAuthContext } from "../contexts/AuthContext";
import axios from "axios";
import { baseUrl } from "../data/constant";
import Loader from "./Loader";

const AffiliatesReport = () => {
  const { currentColor, currentMode, theme } = useStateContext();
  const [selectedUserDetails, setSelectedUserDetails] = useState(null);
  const [selectedSignups, setSelectedSignups] = useState(null);

  const backgroundColor = currentMode === "Dark" ? "#20232a" : "#fafbfb";
  const textColor = currentMode === "Dark" ? "#fafbfb" : "#20232a";

  const [isLoading, setIsLoading] = useState(false);
  const { token } = useAuthContext();
  const [affiliates, setAffiliates] = useState([]);
  const [expandedIndex, setExpandedIndex] = useState(0);
  useEffect(() => {
    fetchRefer();
  }, []);

  async function fetchRefer() {
    setIsLoading(true);
    try {
      const { data } = await axios.get(`${baseUrl}/admin/refer-report`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      if (data.affiliates) {
        setAffiliates(data.affiliates);
      }
    } catch (error) {
      console.error("Failed to fetch users:", error);
    } finally {
      setIsLoading(false);
    }
  }

  const handleViewDetails = (userDetails) => {
    setSelectedUserDetails(userDetails);
  };

  const handleViewSignups = (signups) => {
    setSelectedSignups(signups);
  };

  const closeDialogs = () => {
    setSelectedUserDetails(null);
    setSelectedSignups(null);
  };

  return (
    <>
      {isLoading ? (
        <Loader />
      ) : (
        <Box
          className="p-6 min-h-screen"
          sx={{
            backgroundColor,
            color: textColor,
          }}
        >
          <div className="flex justify-between items-center w-full mb-10">
            <h1
              style={{
                color: currentColor,
              }}
              className="text-xl md:text-4xl font-medium title-font tracking-widest uppercase"
            >
              Affiliates Report
            </h1>
          </div>
          <Divider className="mb-5" sx={{ borderColor: currentColor }} />

          {affiliates.map((affiliate, index) => (
            <Accordion
              key={affiliate._id}
              className="mb-4 rounded-lg shadow-md"
              expanded={expandedIndex === index}
              onChange={() =>
                setExpandedIndex(expandedIndex === index ? -1 : index)
              }
            >
              <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                sx={{
                  backgroundColor: currentColor,
                  color: textColor,
                  borderRadius: "8px",
                  padding: "16px",
                }}
              >
                <Typography>
                  {affiliate.userDetails[0]?.firstName?.toUpperCase()}{" "}
                  {affiliate.userDetails[0]?.lastName?.toUpperCase()}{" "}
                  <span style={{ color: textColor }}>
                    ({affiliate.referralCount})
                  </span>
                </Typography>
              </AccordionSummary>
              <AccordionDetails
                sx={{
                  backgroundColor,
                  color: textColor,
                  p: 3,
                }}
              >
                {/* Referrer Details */}
                <Typography variant="h6" className="mb-4">
                  Referrer Details
                </Typography>
                <TableContainer
                  component={Paper}
                  sx={{
                    mb: 4,
                    borderRadius: "8px",
                    backgroundColor,
                    color: textColor,
                  }}
                >
                  <Table>
                    <TableHead>
                      <TableRow>
                        <TableCell
                          sx={{ color: textColor, textAlign: "center" }}
                        >
                          Name
                        </TableCell>
                        <TableCell
                          sx={{ color: textColor, textAlign: "center" }}
                        >
                          Email
                        </TableCell>
                        <TableCell
                          sx={{ color: textColor, textAlign: "center" }}
                        >
                          Ref-Code
                        </TableCell>
                        <TableCell
                          sx={{ color: textColor, textAlign: "center" }}
                        >
                          Phone
                        </TableCell>
                        <TableCell
                          sx={{ color: textColor, textAlign: "center" }}
                        >
                          Referrals
                        </TableCell>
                        <TableCell
                          sx={{ color: textColor, textAlign: "center" }}
                        >
                          Actions
                        </TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {affiliate.userDetails.map((user, idx) => (
                        <TableRow key={idx}>
                          <TableCell
                            sx={{ color: textColor, textAlign: "center" }}
                          >
                            {user.firstName} {user.lastName}
                          </TableCell>
                          <TableCell
                            sx={{ color: textColor, textAlign: "center" }}
                          >
                            {user.email}
                          </TableCell>
                          <TableCell
                            sx={{ color: textColor, textAlign: "center" }}
                          >
                            {affiliate?.referralCode}
                          </TableCell>
                          <TableCell
                            sx={{ color: textColor, textAlign: "center" }}
                          >
                            {user.phoneNumber}
                          </TableCell>
                          <TableCell
                            sx={{ color: textColor, textAlign: "center" }}
                          >
                            {affiliate.referralCount}
                          </TableCell>
                          <TableCell>
                            <Button
                              variant="contained"
                              size="small"
                              onClick={() => handleViewDetails(user)}
                              sx={{
                                backgroundColor: currentColor,
                                color: "white",
                                textTransform: "capitalize",
                                textAlign: "center",
                              }}
                            >
                              View Details
                            </Button>
                          </TableCell>
                        </TableRow>
                      ))}
                    </TableBody>
                  </Table>
                </TableContainer>

                {/* Users Signed Up */}
                <Typography variant="h6" className="mb-4">
                  Users Signed Up
                </Typography>
                <TableContainer
                  component={Paper}
                  sx={{
                    borderRadius: "8px",
                    backgroundColor,
                    color: textColor,
                  }}
                >
                  <Table>
                    <TableHead>
                      <TableRow>
                        <TableCell sx={{ color: textColor }}>Name</TableCell>
                        <TableCell sx={{ color: textColor }}>Email</TableCell>
                        <TableCell sx={{ color: textColor }}>Phone</TableCell>
                        <TableCell sx={{ color: textColor }}>Gender</TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {affiliate.userSignupDetails.map((signup, idx) => (
                        <TableRow key={idx}>
                          <TableCell sx={{ color: textColor }}>
                            {signup.firstName} {signup.lastName}
                          </TableCell>
                          <TableCell sx={{ color: textColor }}>
                            {signup.email}
                          </TableCell>
                          <TableCell sx={{ color: textColor }}>
                            {signup.phoneNumber || "-"}
                          </TableCell>
                          <TableCell sx={{ color: textColor }}>
                            {signup.gender || "-"}
                          </TableCell>
                        </TableRow>
                      ))}
                    </TableBody>
                  </Table>
                </TableContainer>
                <Button
                  variant="contained"
                  sx={{
                    backgroundColor: currentColor,
                    color: "white",
                    textTransform: "capitalize",
                    mt: 3,
                  }}
                  onClick={() => handleViewSignups(affiliate.userSignupDetails)}
                >
                  View All Signups
                </Button>
              </AccordionDetails>
            </Accordion>
          ))}

          {/* Referrer Details Dialog */}
          <Dialog open={Boolean(selectedUserDetails)} onClose={closeDialogs}>
            <DialogTitle sx={{ color: currentColor }}>
              Referrer Details
            </DialogTitle>
            <DialogContent>
              {selectedUserDetails && (
                <>
                  <Typography>
                    Name: {selectedUserDetails.firstName}{" "}
                    {selectedUserDetails.lastName}
                  </Typography>
                  <Typography>Email: {selectedUserDetails.email}</Typography>
                  <Typography>Gender: {selectedUserDetails.gender}</Typography>
                  <Typography>
                    Phone: {selectedUserDetails.phoneNumber}
                  </Typography>
                </>
              )}
            </DialogContent>
            <DialogActions>
              <Button onClick={closeDialogs} sx={{ color: currentColor }}>
                Close
              </Button>
            </DialogActions>
          </Dialog>

          {/* Signup Details Dialog */}
          <Dialog open={Boolean(selectedSignups)} onClose={closeDialogs}>
            <DialogTitle sx={{ color: currentColor }}>
              Signed Up Users Details
            </DialogTitle>
            <DialogContent>
              {selectedSignups &&
                selectedSignups.map((signup, idx) => (
                  <Box key={idx} className="mb-3">
                    <Typography>
                      Name: {signup.firstName} {signup.lastName}
                    </Typography>
                    <Typography>Email: {signup.email}</Typography>
                    {signup.phoneNumber && (
                      <Typography>Phone: {signup.phoneNumber}</Typography>
                    )}
                  </Box>
                ))}
            </DialogContent>
            <DialogActions>
              <Button onClick={closeDialogs} sx={{ color: currentColor }}>
                Close
              </Button>
            </DialogActions>
          </Dialog>
        </Box>
      )}
    </>
  );
};

export default AffiliatesReport;

import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useStateContext } from "../contexts/ContextProvider";
import { baseUrl } from "../data/constant";
import axios from "axios";
import Loader from "./Loader";
import { toast } from "react-toastify";

const BlogList = () => {
  const navigate = useNavigate();
  const { currentColor, currentMode } = useStateContext();

  const [blogs, setBlogs] = useState([]);
  const [loading, setLoading] = useState(true);
  const [topPriorityBlogId, setTopPriorityBlogId] = useState(null);

  const fetchBlogs = async () => {
    try {
      const token = localStorage.getItem("token");
      const { data } = await axios.get(`${baseUrl}/admin/get-blogs`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      if (data.blogs) {
        setBlogs(data.blogs);
        const topBlog = data.blogs.find(blog => blog.priority === 1);
        if (topBlog) setTopPriorityBlogId(topBlog._id);
      }
    } catch (error) {
      console.error("Error fetching blogs:", error);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchBlogs();
  }, []);

  const isDarkMode = currentMode === "Dark";

  const handleSetTopPriority = async (blogId) => {
    try {
      const token = localStorage.getItem("token");
      const updatedBlogs = blogs.map((blog) =>
        blog._id === blogId
          ? { ...blog, priority: 1 }
          : { ...blog, priority: 0 }
      );
      setBlogs(updatedBlogs);
      setTopPriorityBlogId(blogId);

      await axios.put(
        `${baseUrl}/admin/blog-priority`,
        { blogId },
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      toast("Top priority updated successfully!");
    } catch (error) {
      console.error("Error setting top priority:", error);
    }
  };

  return (
    <div
      className={`max-w-8xl mx-auto p-6 rounded-xl ${isDarkMode ? "bg-gray-800 text-white" : "bg-white text-gray-800"
        }`}
    >
      {loading ? (
        <Loader />
      ) : (
        <>
          <div className="flex justify-between items-center w-full mb-10">
          <h1
            style={{
              color: currentColor,
            }}
            className="text-xl md:text-4xl font-medium title-font tracking-widest uppercase"
          >
            Blogs
          </h1>
          <button
            style={{ backgroundColor: currentColor }}
            onClick={() => navigate("/create-blog")}
            className="py-2 px-5 rounded-md text-white"
          >
            Create Blog
          </button>
        </div>
          
          <div className="mt-8 grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 xl:grid-cols-4 gap-12">
            {blogs.map((blog) => (
              <div
                key={blog._id}
                className={`p-6 rounded-lg shadow-xl border-2 ${isDarkMode
                  ? "border-gray-600 bg-gray-700"
                  : "border-black bg-gray-50"
                  }`}
                style={{
                  borderLeft: `5px solid ${currentColor}`,
                }}
              >
                <h3
                  className={`text-xl font-semibold mb-4 ${isDarkMode ? "text-white" : "text-gray-800"
                    }`}
                >
                  {blog.title}
                </h3>
                <div className="mb-6 overflow-hidden rounded-lg shadow-sm">
                  <img
                    src={blog.image.url}
                    alt={blog.title}
                    className="w-full object-cover rounded-md"
                    style={{
                      objectFit: "cover",
                      objectPosition: "center",
                      height: "200px",
                    }}
                  />
                </div>
                <div
                  className={`text-gray-600 leading-relaxed ${isDarkMode ? "text-gray-300" : ""
                    }`}
                  dangerouslySetInnerHTML={{
                    __html: blog.content.substring(0, 100) + "...",
                  }}
                />
                <small
                  className={`block mt-4 text-sm ${isDarkMode ? "text-gray-400" : "text-gray-500"
                    }`}
                >
                  Published on {new Date(blog.createdAt).toLocaleDateString()}
                </small>
                <div className="mt-4">
                  <button
                    onClick={() => handleSetTopPriority(blog._id)}
                    className={`${blog._id === topPriorityBlogId
                      ? "bg-green-500 text-white"
                      : "bg-gray-500 text-white"
                      } py-2 px-4 rounded-md mt-2`}
                  >
                    {blog._id === topPriorityBlogId
                      ? "Top Priority"
                      : "Set as Top Priority"}
                  </button>
                </div>
              </div>
            ))}
          </div>
        </>
      )}
    </div>
  );
};

export default BlogList;

import React, { useEffect, useState } from "react";
import {
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Typography,
  Button,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  Box,
  Divider,
} from "@mui/material";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { useStateContext } from "../contexts/ContextProvider";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import { useAuthContext } from "../contexts/AuthContext";
import { baseUrl } from "../data/constant";
import Loader from "./Loader";

const AdminNotificationHistory = () => {
  const { currentColor, currentMode } = useStateContext();
  const backgroundColor = currentMode === "Dark" ? "#20232a" : "#fafbfb";
  const textColor = currentMode === "Dark" ? "#fafbfb" : "#20232a";
  const { token } = useAuthContext();
  const [notifications, setNotifications] = useState([]);
  const [isLoading, setIsLoading] = useState(false);

  const navigate = useNavigate();
  const handleNavigate = () => {
    navigate("/create-notification");
  };

  async function fetchHistory() {
    setIsLoading(true);
    try {
      const { data } = await axios.get(`${baseUrl}/admin/get-notifications`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      if (data.notifications) {
        setNotifications(data.notifications);
      }
    } catch (error) {
      console.error("Failed to fetch users:", error);
    } finally {
      setIsLoading(false);
    }
  }

  useEffect(() => {
    fetchHistory();
  }, []);

  const [expandedIndex, setExpandedIndex] = useState(0);

  return (
    <>
      {isLoading ? (
        <div className="flex justify-center items-center min-h-[200px]">
          <Loader color={currentColor} />
        </div>
      ) : (
        <Box
          className="p-6"
          sx={{
            backgroundColor,
            color: textColor,
          }}
        >
          <div className="flex justify-between items-center w-full mb-10">
            <h1
              style={{
                color: currentColor,
              }}
              className="text-xl md:text-4xl font-medium title-font tracking-widest uppercase"
            >
              Admin Notification History
            </h1>
            <Button
              variant="contained"
              color="primary"
              onClick={handleNavigate}
            >
              Send Notification
            </Button>
          </div>
          <Divider sx={{ borderColor: currentColor }} className="mb-5" />

          {notifications.length === 0 ? (
            <Typography
              variant="h6"
              sx={{ textAlign: "center", color: textColor, marginTop: 5 }}
            >
              No notifications available.
            </Typography>
          ) : (
            notifications.map((notification, index) => (
              <Accordion
                key={notification._id}
                expanded={expandedIndex === index}
                onChange={() =>
                  setExpandedIndex(expandedIndex === index ? -1 : index)
                }
                sx={{
                  mb: 3,
                  backgroundColor: currentMode === "Dark" ? "#333" : "#f5f5f5",
                  borderRadius: "8px",
                  boxShadow: 3,
                }}
              >
                <AccordionSummary
                  expandIcon={
                    <ExpandMoreIcon sx={{ color: textColor, fontSize: 30 }} />
                  }
                  sx={{
                    backgroundColor: currentColor,
                    color: textColor,
                    padding: "16px",
                    borderRadius: "8px",
                  }}
                >
                  <Typography>
                    {notification.message} -{" "}
                    <span style={{ color: textColor }}>
                      {notification.users.length} user(s)
                    </span>
                  </Typography>
                </AccordionSummary>
                <AccordionDetails
                  sx={{ backgroundColor, color: textColor, p: 3 }}
                >
                  <TableContainer
                    component={Paper}
                    sx={{
                      borderRadius: "8px",
                      backgroundColor,
                      color: textColor,
                      boxShadow: 3,
                    }}
                  >
                    <Table>
                      <TableHead>
                        <TableRow>
                          <TableCell
                            sx={{ color: textColor, textAlign: "center" }}
                          >
                            First Name
                          </TableCell>
                          <TableCell
                            sx={{ color: textColor, textAlign: "center" }}
                          >
                            Last Name
                          </TableCell>
                          <TableCell
                            sx={{ color: textColor, textAlign: "center" }}
                          >
                            Email
                          </TableCell>
                          <TableCell
                            sx={{ color: textColor, textAlign: "center" }}
                          >
                            Phone Number
                          </TableCell>
                        </TableRow>
                      </TableHead>
                      <TableBody>
                        {notification.users.map((user, idx) => (
                          <TableRow key={idx}>
                            <TableCell
                              sx={{ color: textColor, textAlign: "center" }}
                            >
                              {user.firstName}
                            </TableCell>
                            <TableCell
                              sx={{ color: textColor, textAlign: "center" }}
                            >
                              {user.lastName}
                            </TableCell>
                            <TableCell
                              sx={{ color: textColor, textAlign: "center" }}
                            >
                              {user.email}
                            </TableCell>
                            <TableCell
                              sx={{ color: textColor, textAlign: "center" }}
                            >
                              {user.phoneNumber}
                            </TableCell>
                          </TableRow>
                        ))}
                      </TableBody>
                    </Table>
                  </TableContainer>
                </AccordionDetails>
              </Accordion>
            ))
          )}
        </Box>
      )}
    </>
  );
};

export default AdminNotificationHistory;
